import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import {IconButton, SvgIcon} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
        flexWrap: 'wrap',
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0)
    },
    toolbarTitle: {
        flexGrow: 1,
        lineHeight: "125%",
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    logo: {
        paddingLeft: 0
    }
}));

/**
 *
 * @param user
 * @param signOut
 * @returns {JSX.Element}
 * @constructor
 */
export default function Header({user, signOut}) {
    const classes = useStyles();

    // Only need this one "logout" icon, so just copied svg from https://fonts.google.com/icons
    const renderLogoutIcon = (props) => {
        return (
            <SvgIcon {...props}>
                <path d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z"/>
            </SvgIcon>
        );
    }

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar position="static" elevation={0} className={classes.appBar}>
                <Container maxWidth="lg">
                    <Toolbar className={classes.toolbar}>
                        <Typography edge={"start"}  color="inherit" aria-label="Forside" >
                            <img src={'/dr-logo.png'} alt={"Danske Regioners fællesregional løsning til digital signering"}/>
                        </Typography>
                        <Typography variant="h6" color="inherit" className={classes.toolbarTitle}>
                            Fællesregional løsning<br/>til digital signering
                        </Typography>
                        { user ?
                            <>
                                <Hidden only={"xs"}>
                                    <Button onClick={signOut} startIcon={renderLogoutIcon()} color="inherit" variant="outlined" className={classes.link} aria-label="Log ud">
                                        Log ud
                                    </Button>
                                </Hidden>
                                <Hidden only={["sm", "md", "lg", "xl"]}>
                                    <IconButton onClick={signOut}  color="inherit" component="span" aria-label="Log ud">
                                        {renderLogoutIcon()}
                                    </IconButton>
                                </Hidden>
                            </>
                            : "" }
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
}
