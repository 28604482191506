import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Hidden from '@material-ui/core/Hidden';
import Typography from "@material-ui/core/Typography";
import { japFetch } from "../../jap-utils";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider, IconButton,
	List,
	ListItem,
	ListItemIcon, ListItemSecondaryAction,
	ListItemText,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SignIcon from "@material-ui/icons/HowToReg";
import DeleteIcon from '@material-ui/icons/Delete';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import InfoCard from "./InfoCard";
import IntroCard from "./IntroCard";
import {Redirect, useHistory} from "react-router-dom";
import ServiceAlert from "../common/ServiceAlert";

const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(1),
		},
	},
	button: {
		margin: theme.spacing(1),
	},
	list: {
		width: "100%",
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	paper: {},
	container: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		overflowX: "auto",
	},
	card: {
		height: "100%",
	}
}));

/**
 *
 * @param user
 * @param signOut
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
export default function HomePage({ user, signOut}) {
	const classes = useStyles();
	const [response, setResponse] = useState({ agreements: [] });
	const [doSignOut, setDoSignOut] = useState(false);
	const [error, setError] = useState( {} );
    const history = useHistory();

	useEffect(() => {
		if (user && user.cvr) {
			japFetch(`/api/agreements`)
				.then((res) => {
					console.debug("RES", res)
					res.message.then((message) => {
						setResponse(message);
						setError({});
					});
				})
				.catch((error) => {
                    console.debug("Fetch agreements:", error);
					if (!error.userIsValid) {
						setDoSignOut(true);
					} else {
						console.error("Fetch agreements:", error);
						setError( {...error, alert: true } );
					}
				});
		}

	}, [user,history]);

	if (doSignOut) {
		setDoSignOut(false);
		signOut();
	}

	const renderPendingListItem = (row) => {
		return (
			<ListItem
				onClick={(event) => {
					history.push({
						pathname: "/sign/" + row.agreementId,
						state: { agreement: row },
					});
				}}
				alignItems="flex-start" button>
				<ListItemIcon title={"Indgå aftale"}>
					<SignIcon
						color={"primary"}
						onClick={(event) => {
							history.push({
								pathname: "/sign/" + row.agreementId,
								state: { agreement: row },
							});
						}}
					/>
				</ListItemIcon>
				<ListItemText
					primary={row.title + (row.version ? ' (' + row.version + ')' : '')}
					secondary={"Afventer underskrift"}
				/>
			</ListItem>
		);
	};

	const renderSignedListItem = (row) => {
        console.log("renderSignedListItem",user);
		return (
			<ListItem
				alignItems="flex-start" button component={"a"} href={
				"/api/documents/" + row.agreementId +
				"?signatureId=" + row.signedBySignatureId +
				"&attach=" + encodeURIComponent(row.title) +
				"&token=" + encodeURIComponent(user.access_token)
			}>
				<ListItemIcon title={"Hent aftaledokument (PDF)"}>
					<SaveAltIcon color={"primary"} />
				</ListItemIcon>
				<ListItemText
					primary={row.title + (row.version ? ' (' + row.version + ')' : '')}
					secondary={
						"Indgået d. " +
						row.signedDate.substring(0, row.signedDate.indexOf(" ")) +
						" af " +
						row.signedBy
					}
				/>

				<ListItemSecondaryAction>
					<Hidden only={["xs", "sm" ]}>
						<Button
					//		href={"#"}
					//		rel="noreferrer"
							startIcon={<DeleteIcon />}
							color="secondary"
							onClick={(event) => {
								history.push({
									pathname: "/sign/" + row.agreementId,
									state: { agreement: row },
								});
							}}
						>
							Opsig aftale
						</Button>
					</Hidden>
					<Hidden only={["md", "lg", "xl"]}>
						<IconButton edge="end" color="secondary" aria-label="Opsig aftale">
							<DeleteIcon />
						</IconButton>
					</Hidden>
				</ListItemSecondaryAction>
			</ListItem>
		)
	};

	const renderCancelledListItem = (row) => {
		return (
			<ListItem alignItems="flex-start" button /*component={"a"} href={
				serverPrefix + "/api/documents/" + row.agreementId +
				"?attach=" + encodeURIComponent(row.title) +
				"&token=" + encodeURIComponent(user.access_token)  */
				onClick={(event) => handleToggleDownloadDialog(event, row)
			}>
				<ListItemIcon title={"Hent aftaledokument (PDF)"}>
					<SaveAltIcon color={"primary"} />
				</ListItemIcon>
				<ListItemText
					primary={row.title + (row.version ? ' (' + row.version + ')' : '')}
					secondary={
						"Opsagt d. " +
						row.cancelledDate.substring(0, row.cancelledDate.indexOf(" ")) +
						" af " +
						row.cancelledBy +
						" (oprindeligt indgået d. " +
						row.signedDate.substring(0, row.signedDate.indexOf(" ")) +
						" af " +
						row.signedBy + ")"
					}
				/>
			</ListItem>
		)
	}

	/* Suggestion not to have the dialog step but rather do actions directly from the list
	const [detailRow, setDetailRow] = React.useState({});
	const [detailDialog, setDetailDialog] = React.useState(false);
	const handleCloseDetailDialog = () => {
		setDetailDialog(false);
		setDetailRow({});
	};
	const handleToggleDetailDialog = (event, row) => {
		setDetailDialog(!detailDialog);
		setDetailRow(!detailDialog ? row : {});
	};

	const renderDetailDialog = () => {
		return (
			<Dialog
				className={classes.paper}
				fullWidth={true}
				maxWidth={"sm"}
				keepMounted
				open={detailDialog}
				onClose={handleCloseDetailDialog}
				aria-labelledby="detail-dialog-title"
			>
				<DialogTitle id="detail-dialog-title">{detailRow.title}</DialogTitle>
				<DialogContent dividers>
					<List className={classes.root} dense={true}>
						{ detailRow.version &&
						<ListItem>
							<ListItemText primary="Dokumentversion:" secondary={detailRow.version}/>
						</ListItem>
						}
						<ListItem>
							<ListItemText
								primary="Status:"
								secondary={
									detailRow.state === "PENDING"
										? "Afventer underskrift"
										: detailRow.state === "SIGNED"
										? "Underskrevet"
										: "Opsagt"
								}
							/>
						</ListItem>
						<ListItem>
							<ListItemText primary="Underskrevet af:" secondary={detailRow.signedBy} />
						</ListItem>
						<ListItem>
							<ListItemText primary="Underskrevet d.:" secondary={detailRow.signedDate} />
						</ListItem>
						{detailRow.state === "CANCELLED" && (
							<React.Fragment>
								<ListItem>
									<ListItemText primary="Opsagt af:" secondary={detailRow.cancelledBy} />
								</ListItem>
								<ListItem>
									<ListItemText primary="Opsagt d.:" secondary={detailRow.cancelledDate} />
								</ListItem>
							</React.Fragment>
						)}
					</List>
				</DialogContent>
				<DialogActions>
					{detailRow.state !== "CANCELLED" && (
						<Button
							href={"#"}
							rel="noreferrer"
							startIcon={<DeleteIcon />}
							color="secondary"
						>
							Opsig aftale
						</Button>
					)}
					<Button
						color="default"
						href={
							serverPrefix + "/api/documents/" + detailRow.agreementId +
							"?attach=" + encodeURIComponent(detailRow.title) +
							"&token=" + encodeURIComponent(user.access_token)
						}
						startIcon={<CloudDownloadIcon />}
					>
						Hent aftale
					</Button>
					<Button autoFocus onClick={handleCloseDetailDialog}>
						Luk
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
*/

	/* Dialog to select if Agreement document or Cancel Agreement document for download */
	const [downloadRow, setDownloadRow] = React.useState({});
	const [downloadDialog, setDownloadDialog] = React.useState(false);
	const handleCloseDownloadDialog = () => {
		setDownloadDialog(false);
		setDownloadRow({});
	};

	const handleToggleDownloadDialog = (event, row) => {
		console.log("handleToggleDownloadDialog");
		setDownloadDialog(!downloadDialog);
		setDownloadRow(!downloadDialog ? row : {});
	};

	const renderDownloadDialog = () => {
		return (
			<Dialog
				className={classes.paper}
				fullWidth={true}
				maxWidth={"sm"}
				keepMounted
				open={downloadDialog}
				onClose={handleCloseDownloadDialog}
				aria-labelledby="download-dialog-title"
			>
				<DialogTitle id="download-dialog-title">{"Download dokument"}</DialogTitle>
				<DialogContent dividers>
					<List className={classes.root} dense={true}>
						<ListItem alignItems="flex-start" button component={"a"} href={
							"/api/documents/" + downloadRow.agreementId +
							"?signatureId=" + downloadRow.signedBySignatureId +
							"&attach=" + encodeURIComponent(downloadRow.title) +
							"&token=" + encodeURIComponent(user.access_token)
						}>
						<ListItemIcon title={"Hent aftaledokument (PDF)"}>
								<SaveAltIcon color={"primary"} />
							</ListItemIcon>
							<ListItemText primary={downloadRow.title} secondary={'Underskrevet af: ' + downloadRow.signedBy + ' (' + downloadRow.signedDate + ')'} />
						</ListItem>
						<ListItem alignItems="flex-start" button component={"a"} href={
							"/api/documents/" + downloadRow.agreementId +
							"?signatureId=" + downloadRow.cancelledBySignatureId +
							"&attach=" + encodeURIComponent("Opsig Databehandleraftale") +
							"&token=" + encodeURIComponent(user.access_token)
						}>
							<ListItemIcon title={"Hent opsigelsesdokument (PDF)"}>
								<SaveAltIcon color={"primary"} />
							</ListItemIcon>
							<ListItemText primary={"Opsigelsesdokument for DNHF databehandleraftale"} secondary={'Opsagt af: ' + downloadRow.cancelledBy + ' (' + downloadRow.cancelledDate + ')'} />
						</ListItem>
					</List>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleCloseDownloadDialog}>
						Luk
					</Button>
				</DialogActions>
			</Dialog>
		)
	}


	if (user && user.cvr) {
		return (
			<>
				<Container maxWidth="lg" className={classes.container}>
					<main>
						<Grid container spacing={2}>
							<Hidden mdUp>
								<Grid item xs={12} md={3} >
									<InfoCard partner={response} />
								</Grid>
							</Hidden>
							<Grid item xs={12} md={9}>
								<IntroCard agreement={response.agreements.find(a => a.state === "PENDING")} history={history} />
							</Grid>
							<Hidden smDown>
								<Grid item xs={12} md={3} >
									<InfoCard partner={response} />
								</Grid>
							</Hidden>
							<Grid item xs={12} >
								<Card className={classes.card}>
									<CardContent>
										<Typography variant="subtitle1" color="primary">Aftaleoversigt:</Typography>
										<List className={classes.root}>
											{response.agreements
												.sort((a, b) => a.agreementId > b.agreementId ? -1 : 1)
												.map((row, idx) => (
												<React.Fragment key={row.agreementId}>
													{idx > 0 && <Divider variant="inset" component="li" />}
													{row.state === "PENDING" && renderPendingListItem(row)}
													{row.state === "SIGNED" && renderSignedListItem(row)}
													{row.state === "CANCELLED" && renderCancelledListItem(row)}
												</React.Fragment>
											))}
										</List>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</main>
				</Container>
				{/* renderDetailDialog(detailRow) */}
				{renderDownloadDialog(downloadRow)}
				<ServiceAlert error={error} />
			</>
		);
	}	else {
        console.log("Homepage Redirect",user);
		return (
			<Redirect
				to={{
					pathname: "/login",
					state: { user: user },
				}}

			/>
		);
	}
}
