import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {Redirect, useParams} from "react-router-dom";
import { Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import ServiceAlert from "../common/ServiceAlert";
import MitIDFrame from "../mitid/MitIDFrame";
import TabPanel from "../tabs/TabPanel";

const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(1),
		},
	},
	button: {
		margin: theme.spacing(1),
	},
	list: {
		width: "100%",
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	paper: {},
	container: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		overflowX: "auto",
	},
}));

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function SignPage({ user}) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const history = useHistory();
    const params = useParams();
	const [error, setError] = useState( {} );

	const showServiceAlert = (message) => {
		setError({ message: message, alert: true });
	};

	const agreementSigned = (errorMessage, status) => {
		console.debug("status", status)
		switch (status) {
			case 200:
				setMessage("Databehandleraftalen på DNHF er nu underskrevet. Du vender nu tilbage til aftaleoversigten, hvorfra du kan hente aftalen som et PDF-dokument.");
				break;
			case 304:
				setMessage("Handlingen er afbrudt. Dokumentet er ikke underskrevet.");
				break;
			case 401:
				setMessage("Der er sket en fejl i forbindelsen med NemId. Dokumentet er ikke underskrevet. Prøv igen senere.");
				break;
			default:
				setMessage("Der er sket en ukendt fejl. Dokumentet er ikke underskrevet. Prøv igen senere.");
		}
		setOpen(true);
	};

	const toggleAlert = () => {
		if (open) {
			// Update list if status 200
			history.push("/");
		}
		setOpen(!open);
	};

	if (user) {
		return (
			<>
				<Container maxWidth="lg" className={classes.container}>
					<main>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Paper>

									<TabPanel>
                                            <MitIDFrame
                                                width={300}
                                                height={400}
                                                agreementId={params.agreementId}
                                                agreementSigned={agreementSigned}
                                                showServiceAlert={showServiceAlert}
                                            />

									</TabPanel>
								</Paper>
							</Grid>
						</Grid>
					</main>
				</Container>
				<Dialog
					open={open}
					onClose={toggleAlert}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title" color="primary">
						{"Underskriv aftale"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">{message}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={toggleAlert} color="primary" autoFocus>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
				<ServiceAlert error={error} />
			</>
		);
	}  else {
		return (
			<Redirect
				to={{
					pathname: "/login",
					state: { user: user },
				}}
			/>
		);
	}
}
