import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function ServiceAlert (props ) {
    const [error, setError] = useState( { alert: false } ); // TODO state is not really needed if all we do is reload page

    useEffect(() => {
        if(props.error && props.error.alert)
            console.error("Service alert ->", props.error ); // we no longer shows the error in the UI, but may still be useful in the console
        setError( { ...props.error } );
    }, [ props.error ]);

    const handleCloseAlert = () => {
        setError( { alert: false } );
        window.location.reload();
    };

    return (
        <Dialog
            open={error.alert === true}
            onClose={handleCloseAlert}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Fejlbesked"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Der er opstod en fejl i forbindelse med forespørgslen.
                    <br/>
                    Genindlæs venligst siden eller prøv igen senere.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseAlert} color="primary" autoFocus>
                    Genindlæs
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ServiceAlert