import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import { japLog,} from "../../jap-utils";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        margin: "auto",
        display: "flex",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    nemidFrame: {
        margin: "auto",
        maxWidth: "300px",
        height: "400px",
    },
    submitBtn: {
        alignSelf: "flex-end",
    },
}));


const signDocument = (content, command, signOptions, agreementId, agreementSigned, showServiceAlert) => {
    console.log("signDocument - begin");
    fetch(`/api/mitid/signing-result?agreementId=${agreementId}`, {

        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify( {signingResult:{
            result: content,
            type: command,
            name: signOptions.document,
            format: signOptions.format,
            correlationId: signOptions.correlationId,
        }}),
    })
        .then((res) => {
            if (res.ok) {
                return res.text();
            }
            throw res;
        })
        .then((content) => {
            agreementSigned("", 200);
        })
        .catch((errorResponse) => {
            japLog("nemid-sign-document", errorResponse);

            if (errorResponse instanceof TypeError) {
                showServiceAlert(errorResponse.message);
            } else if (errorResponse.status === 401) {
                agreementSigned("Fejl ved validering af MitId.", 401);
            } else if (errorResponse.status === 304) {
                agreementSigned("Afbrudt", 304);
            } else {
                showServiceAlert(errorResponse.status + " " + errorResponse.statusText);
            }
        });
    console.log("signDocument - end");
};

/**
 * @param width Width of the iframe. Mandatory
 * @param agreementSigned callback on agreement signed. Returns errorMessage and agreementId.
 * @param showServiceAlert callback to show alert when service fails
 */
const MitIDFrame = ({agreementId, agreementSigned, showServiceAlert}) => {
    const classes = useStyles();
    const [signOptions, setSignOptions] = useState({});

    const [errorMessage, setErrorMessage] = useState();

    const loadFrameOptions = () => {
        fetch(`/api/mitid/sign?agreementId=${agreementId}`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw response;
            })
            .then((data) => {
                setSignOptions(data)
            })
            .catch(errorResponse => {
                if (errorResponse instanceof TypeError)
                    showServiceAlert(errorResponse.message);
                else
                    showServiceAlert(errorResponse.status + " " + errorResponse.statusText);
            });
    };


    useEffect(() => {
        loadFrameOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const onSigningClientMessage = (event) => {
            event.preventDefault();
            console.debug("onSigningClientMessage - begin")
            // Signing payload produced by SP, containing signature parameters and DTBS.
            // OWASP HTML5 Security - must protect calling frame unsafe web messaging
            if (event.origin === signOptions.signingClientUrl) {
                console.error("event '" + event.origin + "' origin rejected");
                return
            }

            console.debug(signOptions)
            const win = document.getElementById("signing-window").contentWindow;
            const message = event.data

            // Message received from SigningClient when loaded and ready
            if (message.command === "SendParameters") {
                console.debug("SP: Posting parameters to signing client");
                const params = {
                    command: 'parameters',
                    content: JSON.parse(signOptions.signingPayload)
                };
                win.postMessage(params, '*');
            }

            // Message received from SigningClient when either the document has been signed,
            // a signing error has occurred, or when the user has cancelled the signing operation.
            if (message.command === "signedDocument" || message.command === "errorResponse" || message.command === "cancelSign") {
                console.debug("SP: Signing Result: " + message.command);
                signDocument(message.content, message.command, signOptions, agreementId, agreementSigned, showServiceAlert);
            }
            console.debug("onSigningClientMessage - end")
        }

        // Listen for events from the Signing Client
        if (Object.keys(signOptions).length) {
            window.addEventListener("message", onSigningClientMessage);
        }
        // clean up when signOptions change
        return () => window.removeEventListener("message", onSigningClientMessage);
    }, [signOptions, agreementSigned, agreementId, showServiceAlert]);


    return (
        <React.Fragment>
            {signOptions ? (
                <div>
                    <iframe
                        id="signing-window"
                        className={"responsive-iframe"  }
                        name="target_iframe"
                        title="MitID"
                        allowFullScreen={true}
                        sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                        src={signOptions.signingClientUrl}
                        width={classes.width}
                        height={classes.height}
                        scrolling="no"
                        frameBorder="0"
                    />
                </div>
            ) : errorMessage !== null ? (
                showServiceAlert(errorMessage)
            ) : ""}
        </React.Fragment>
    );
};


export default MitIDFrame;
