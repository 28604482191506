//import {createMuiTheme} from "@material-ui/core/styles"; Remove material UI findDom error
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "rgb(0, 98, 125)",
			light: "rgb(224, 236, 240)",
			dark: "rgb(0, 49, 62)",
		},
		error: {
			main: "#f44336",
		},
	},
	typography: {
		fontFamily: [
			"Roboto",
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		h1: {
			fontSize: "1.25rem",
			fontWeight: 500,
		},
	},
});

export default theme;
