import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
    card: {
        height: "100%",
        backgroundColor: "rgb(224, 236, 240)"
    },
}));

/**
 *
 * @param partner
 * @returns {JSX.Element}
 * @constructor
 */
export default function InfoCard({partner}) {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography variant={"body1"} color="primary" gutterBottom>
                    Du handler på vegne af:
                </Typography>
                <Hidden only={"sm"}>
                    <Typography variant="subtitle1" gutterBottom>
                        {partner.entityName}<br/>CVR: {partner.cvrNumber}
                    </Typography>
                </Hidden>
                <Hidden only={["xs", "md", "lg", "xl"]}>
                    <Typography variant="subtitle1" gutterBottom>
                        {partner.entityName} &mdash; CVR: {partner.cvrNumber}
                    </Typography>
                </Hidden>
                {partner.postalAddress && (
                    <Typography variant={"body2"}>
                        {partner.postalAddress.streetName} {partner.postalAddress.streetBuildingIdentifier}
                        <br />
                        {partner.postalAddress.postCodeIdentifier} {partner.postalAddress.districtName}
                        <br />
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
}
