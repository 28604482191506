import React from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {useHistory} from "react-router-dom";
import {List, ListItem, ListItemText} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(3), paddingBottom: theme.spacing(3), overflowX: "auto",

    }, card: {
        height: "100%",
    },
}));

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function LogoutPage() {
    const classes = useStyles();
    const history = useHistory();


    return (<>
        <Container component="main" maxWidth="lg" className={classes.container}>
                <Grid container alignContent={'stretch'}>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    Hvis andre har adgang til denne computer, smartphone eller tablet, skal du:
                                </Typography>
                                <List dense={false}>
                                    <ListItem>
                                        <ListItemText primary="- Lukke browser helt ned - ikke kun fanebladet, eller"/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="- Slette internetbrowserens midlertidige filer."/>
                                    </ListItem>
                                </List>
                                <Grid container spacing={2}>
                                    <Grid item xs={10}>
                                    </Grid>
                                    <Button
                                        className={classes.submitBtn}
                                        color={"primary"}
                                        variant={"outlined"}
                                        type="submit"
                                        onClick={() => history.push("/")}
                                    >Til Forside
                                    </Button>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
        </Container>
    </>);
}
