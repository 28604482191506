import React, {useEffect} from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ServiceAlert from "../common/ServiceAlert";
import {CircularProgress, Input} from "@material-ui/core";
import {useHistory} from "react-router-dom";

import {
    extractPayloadFromJwt, saveUserToCookie,
} from "../../jap-utils";


const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(3), paddingBottom: theme.spacing(3), overflowX: "auto",
    }, card: {
        height: "100%",
    }, btn: {
        color: "#FFFFFF", backgroundColor: "#0060E6", height: "48px", marginLeft: "24px", marginRight: "24px", textTransform: 'none'
    }
}));

const {useState} = React;


/**
 * @param config
 * @param user
 * @param signIn
 * @param signOut
 * @returns {JSX.Element}
 * @constructor
 */
export default function LoginPage({ config, user, signIn, signOut }) {
	const classes = useStyles();
	const [error, setError] = useState( {} );
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState();
	const [cvrNumber, setCvrNumber] = useState("");
    const history = useHistory();

    const showServiceAlert = (message) => {
		setError({ message: message, alert: true });
	};

    function handleLoginResponse (token){
        setErrorMessage(null);
        saveUserToCookie(token);
        signIn();
        const path = "/login";
        history.push(path);
    }
    useEffect(() => {
        if (!user){
            showLogin();
        }
    },[config,user]);

    function handleErrorResponse(errorResponse){
        console.error(errorResponse)
        setErrorMessage(errorResponse);
    }

    function showLogin() {
        if (Object.keys(config).length > 0) {
            const options = {
                apiKey: config.rsydNemLogin_apiKey,
            };
            const server = config.rsydNemLogin_url;
            const loginPlaceHolder = document.getElementById("loginPlaceHolder");
            if (loginPlaceHolder) {
                window.JapNemLogin(server, options).login('loginPlaceHolder', handleLoginResponse, handleErrorResponse);
            }
        }
    }

	const resetSession = () => {
		setErrorMessage(null);
		setCvrNumber("");
        signOut();
	};

    const onCvrNumberInput = (e) => {
        e.preventDefault()
        if (e.target.validity.valid) {
            setCvrNumber(e.target.value);
        }
    };

    const addCvrToPayload = (e) => {
        e.preventDefault();

        if (!loading) setLoading(true);
        const accessToken = user.access_token
        const payLoad = JSON.stringify(user)

        fetch(`/api/login/addPartnerToToken?cvrNumber=${cvrNumber}`, {
			method: "POST", // *GET, POST, PUT, DELETE, etc.
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
			},
			body: payLoad,
		}).then((res) => {
				if (res.ok) {
					return res.text();
				}
				throw res;
			}).then((jwt) => {
				// response is a jwt in 3 parts, where all parts are base64 encoded. We're generally only interested in the payload part.
				setErrorMessage(null);
				const payload = extractPayloadFromJwt(jwt);
				saveUserToCookie(payload);
				signIn();
                setLoading(false);
                history.push("/");
			}).catch((errorResponse) => {
				setLoading(false);
				if(errorResponse instanceof TypeError){
                    showServiceAlert(errorResponse.message);
                }else{
                    console.log("errorResponse.status: ", errorResponse.status);
                }
				switch (errorResponse.status) {
					case 400:
						setErrorMessage("Ugyldigt CVR-nummer.");
						break;
					case 401:
						setErrorMessage("Ingen tegningsret på det angivne CVR-nummer.");
						break;
					case 403:
						// in case the web service restarts and the JWT secret no longer matches
                        setErrorMessage("Login ugyldigt eller udløbet. Prøv igen senere.");
                        showServiceAlert(errorResponse.status +  "Login ugyldigt eller udløbet. Prøv igen senere.");
                        resetSession();
						break;
					case 404:
						setErrorMessage("Der er ikke registreret henvisninger på pågældende CVR.");
						break;
					case 500:
						console.log("Ukendt problem. Prøv igen senere.");
						setErrorMessage("Ukendt problem. Prøv igen senere.");
						break;
					default:
						setErrorMessage("Ukendt problem."); // serves as a flag for prevent iframe from loading
						showServiceAlert(errorResponse.status + " " + errorResponse.statusText);
						break;
				}
			});
	};

	return (
		<>
			<Container component="main" maxWidth="lg" className={classes.container}>
				<main>
					<Grid container spacing={2} alignContent={'stretch'}>
						<Grid item xs={12} md={6}>
							<Card className={classes.card}>
								<CardContent>
									<Typography variant={"body1"} gutterBottom={true}>
										Log ind for at indgå eller opsige en databehandleraftale med Region
										Syddanmark på Den Nationale
										Henvisningsformidling (DNHF).
									</Typography>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12} md={6}>
							{!user ? (
									<Card className={classes.card}>
                                        <div id="loginPlaceHolder" style={{padding: "15px"}}/>
									</Card>
								) : (
									<Card className={classes.card}>
										<form className={classes.cvrForm} onSubmit={addCvrToPayload}
                                              style={{padding: "15px"}}>

											<Typography color={"primary"} className={classes.cvrHeader} variant={"h4"}>
												CVR-nummer
											</Typography>
											<Typography color={"textSecondary"} className={classes.cvrHelperText}>
												Angiv venligst CVR-nummer på din virksomhed.
											</Typography>
											<Grid className={classes.cvrInput} container spacing={2} justifyContent="space-between">
												<Grid item xs={8}>
													<Input
														error={errorMessage !== null}
														inputProps={{type: "text", maxLength: 12 , pattern: "[0-9]*"}}
														fullWidth
														placeholder="CVR-nummer"
														onChange={onCvrNumberInput}
                                                        value={cvrNumber}
                                                    />
													{errorMessage !== null ? (
														<Typography color={"error"} variant={"caption"}>
															{errorMessage}
														</Typography>
													) : null}
												</Grid>
												<Button
													className={classes.submitBtn}
													disabled={!cvrNumber || loading}
													color={"primary"}
													variant={"outlined"}
													type="submit"
												>
													{loading ? (
														<span style={{paddingRight: "10px"}}>
									<CircularProgress className={classes.loading} size={10}></CircularProgress>
									</span>) : null}
                                        Bekræft
                                    </Button>
                                </Grid>
                            </form>
                        </Card>)}
                    </Grid>
                </Grid>
            </main>
            <ServiceAlert error={error}/>
        </Container>
    </>);
}
