import React, {useEffect, useState} from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import HomePage from "./components/home/HomePage";
import SignPage from "./components/sign/SignPage";
import LoginPage from "./components/login/LoginPage";
import LogoutPage from "./components/logout/LogoutPage";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import ServiceAlert from "./components/common/ServiceAlert";
import theme from "./Theme";
import {loadUserFromCookie, removeUserFromCookie} from "./jap-utils";
import CssBaseline from "@material-ui/core/CssBaseline";
import {makeStyles} from "@material-ui/core/styles";
import {loadConfig} from "./config";

// Set footer and wrapper to stick footer to bottom
const useStyles = makeStyles((theme) => ({
	footer: {
		height: "64px",
	},
	wrapper: {
		minHeight: "calc(100vh - 64px)",
	},
}));

export default function App(props) {
    const [user, setUser] = useState(loadUserFromCookie());
    const [config, setConfig] = useState({});
    const [error, setError] = useState( {} );

    useEffect(() => {
        loadConfig().then(config => {
            if (config) {
                setConfig(config)
            }
        }).catch((errorResponse) => {
            setError(errorResponse.message);
            console.log(errorResponse)
        });

    }, [config]);



	function signIn() {
		 console.log("signIn")
		setUser(loadUserFromCookie());
	}

    /**
     * Called when user clicks the logout button.
     * The logout-function calls the nemlogin-service to log out
     * the Nemlogin session, and after that it
     */
    function doLogout(){
        loadConfig().then(config => {
            if (config) {
                const options = {
                    apiKey: config.nemLoginKey,
                };
                const server = config.nemLoginUrl;
                removeUserFromCookie();

                setUser(null);
                window.JapNemLogin(server, options).logout();
                const path = "/logout";
                window.location.replace(path);
            } else {
                console.error("Could not load config. Page will not function as expected.")
            }
        });
    }

	function signOut() {
		console.debug("Signout")
        doLogout();

	}

	const classes = useStyles();

	return (
		<React.Fragment>
			<CssBaseline />
			<ThemeProvider theme={theme}>
                <ServiceAlert error={error}/>
				<Router>
					<div className={classes.wrapper}>
						<Header user={user} signOut={() => signOut()} />
						<Switch>
							<Route exact path="/login" >
								<LoginPage config={config} user={user} signIn={() => signIn()} signOut={() => signOut()}/>
							</Route>
                            <Route exact path="/logout">
                                <LogoutPage signOut={() => signOut()} />
                            </Route>
                            <Route exact path="/" >
                                <HomePage user={user} signOut={() => signOut()}  />
                            </Route>
                            <Route path="/sign/:agreementId" >
                                <SignPage user={user} signOut={() => signOut()}  />
                            </Route>
							<Redirect to="/" />
						</Switch>
					</div>
					<div className={classes.footer}>
						<Footer version={config.version} user={user} />
					</div>
				</Router>
			</ThemeProvider>
		</React.Fragment>
	);
}
