import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import SignIcon from "@material-ui/icons/HowToReg";
import Button from "@material-ui/core/Button";
import {Link, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(0),
        marginBottom: theme.spacing(2)
    },
    card: {
        height: "100%",
    },
}));

/**
 *
 * @param agreement Most recent non-signed agreement
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
export default function IntroCard({agreement}) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography variant="subtitle1" color="primary" gutterBottom>
                    Du er nu logget ind på Danske Regioners fællesregionale løsning til digital signering
                </Typography>
                <Typography variant="body2" gutterBottom>
                    Her kan du opfylde din pligt til at indgå en databehandleraftale med Region Syddanmark, på vegne af de fem regioner, på Den Nationale Henvisningsformidling.
                    Du har følgende muligheder:
                </Typography>
                <List dense={true}>
                    <ListItem>
                        <ListItemIcon>
                            <CheckIcon />
                        </ListItemIcon>
                        <ListItemText primary="At indgå den krævede databehandleraftale med Region Syddanmark på vegne af de fem regioner på Den Nationale Henvisningsformidling." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckIcon />
                        </ListItemIcon>
                        <ListItemText primary="At downloade din indgåede databehandleraftale." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckIcon />
                        </ListItemIcon>
                        <ListItemText primary="At opsige din indgåede databehandleraftale." />
                    </ListItem>
                </List>
                <Typography variant="body2" gutterBottom >
                    Yderligere information angående kravet om indgåelse af en databehandleraftale mellem brugere af DNHF
                    og Region Syddanmark kan findes på <Link href={"https://dnhf.dk"} target={"_blank"}>dnhf.dk</Link> under fanebladet "Retningslinjer om informationssikkerhed".
                </Typography>
                { agreement != null &&
                    <React.Fragment>
                        <Typography variant="subtitle1" color="primary" gutterBottom>
                            <b>{agreement.title} {agreement.version && '(' + agreement.version + ')'}</b> afventer din underskrift.
                        </Typography>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<SignIcon />}
                            title={"Underskriv aftale"}
                            onClick={() => {
                                console.log(agreement)
                                history.push({
                                    pathname: "/sign/" + agreement.agreementId,
                                    state: { agreement: agreement },
                                });
                            }}
                        >
                        Underskriv
                        </Button>
                    </React.Fragment>
                }
                <Typography variant="body2" gutterBottom>
                    Fremover kan du her downloade <Link href={"/TilsynsrapportDNHF.pdf"} target={"_blank"} >tilsynsdokument for DHNF</Link>, som du kan bruge til at opfylde kravet om, at du som dataansvarlig skal føre tilsyn med Region Syddanmark som databehandler.
                </Typography>
                <Typography variant="body2">
                    Du kan se en samlet oversigt over dine aftaler i "Aftaleoversigten" nedenfor.
                </Typography>
            </CardContent>
        </Card>
    );
}
