import {loadConfig} from "./config";

export function loadUserFromCookie() {
    const user = userLoggedOn();
    if (user) {
        return JSON.parse(atob(user));
    } else {
        return null;
    }
}
function userLoggedOn() {
    return sessionStorage.getItem("user-session");
}

export function saveUserToCookie(user) {
    console.debug("saveUserToCookie",user)
    sessionStorage.setItem("user-session", btoa(JSON.stringify(user)));
}

/**
 *  Return payload as JSON
 *  @param jwt Base64 encode JWT
 */
export function extractPayloadFromJwt(jwt) {
    return JSON.parse(atob(jwt.split(".")[1]));
}



export function removeUserFromCookie() {
    console.debug("removeUserFromCookie")
    sessionStorage.removeItem("user-session");
}

export function japLog(tag, message) {
    // get user session from cookie
    const user = loadUserFromCookie();



    const response = {
        status: 200,
        message: null,
    };

    return new Promise((resolve, reject) => {
        if (!user) {
            response.status = 9999;
            response.message = "No active sessions";
            return reject(response);
        }
        console.debug("japLog",user)
        const props = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: "Bearer " + user.access_token,
            },
            body: JSON.stringify({tag, message})
        }

        fetch(`/api/log`, props)
            .then(res => {  // access token was valid
                if (res.ok) {
                    return resolve(response);
                }
        })
    })
}

export function japFetch(url, props) {
    // prepare response
    const response = {
        userIsValid: true,
        status: 200,
        message: null,
    };


    // get user session from cookie
    const user = loadUserFromCookie();

    // if no props are present it will default to a GET method
    if (!props) {
        props = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        }
    }

    // Create a promise to wrap the entire request negotiation into
    return new Promise((resolve, reject) => {
        if (!user) {
            response.status = 9999;
            response.message = "No active sessions";
            return reject(response);
        }

        // extend the headers with the access token
        props.headers.authorization = "Bearer " + user.access_token;

        fetch(url, props)
            .then(res => {  // access token was valid
                if (res.ok) {
                    response.message = res.json();
                    return resolve(response);
                }
                throw res;
            }).catch(e => { // something went wrong
            if (e.status === 401) { // the access token wasn't valid. Try fetch a new one
                loadConfig().then(config => {
                    fetch(`${config.loginUrl}/oauth/access_token`, {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            grant_type: "refresh_token",
                            refresh_token: user.refresh_token
                        })
                    }).then(res => {
                        if (res.ok) { // token refreshed successfully
                            return res.json();
                        }
                        throw res; // refresh failed
                    }).then(json => { // save the new access token and retry the original request
                        user.access_token = json.access_token;
                        saveUserToCookie(user);
                        props.headers.authorization = "Bearer " + user.access_token;
                        fetch(url, props).then(res => {
                            if (res.ok) {
                                response.message = res.json();
                                return resolve(response);
                            }
                            throw res;
                        }).catch(e => {
                            response.status = e.status;
                            e.text().then(errorMessage => {
                                response.message = errorMessage;
                                return reject(response);
                            })
                        });
                    }).catch(e => { // refresh failed invalidating the user
                        response.userIsValid = false;
                        e.text().then(errorMessage => {
                            response.message = errorMessage;
                            return reject(response);
                        })
                    });
                });
            } else {
                if (e.text) {
                    e.text().then(errorMessage => {
                        response.status = e.status;
                        response.message = errorMessage;
                        return reject(response);
                    })
                } else {
                    // unknow error that doesn't have a body
                    response.status = 500;
                    response.message = e.message;
                    return reject(response);
                }
            }
        })
    });
}