let cachedConfig = null;

function loadConfig() {
    if(cachedConfig != null) {
        return new Promise((resolve, reject) => {
            //console.log("CACHED CONF", cachedConfig)
            resolve(cachedConfig);
        })
    } else {
        return fetch(`/client-config`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
            throw res;
        }).then(json => {
            cachedConfig = {loginUrl: json.loginUrl, version: json.version, rsydNemLogin_url: json.rsydNemLogin.url, rsydNemLogin_apiKey: json.rsydNemLogin.apiKey };
            //console.log("FETCHED CONF", cachedConfig)
            return cachedConfig;
        }).catch(e => {
            console.error("Failed to load client configuration");
        });
    }
}

export {loadConfig}