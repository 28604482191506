import {makeStyles} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import {Link} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
        flexWrap: 'wrap',
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    grow: {
        flexGrow: 1,
    }
}));

/**
 *
 * @param user
 * @param signOut
 * @returns {JSX.Element}
 * @constructor
 */
export default function Footer({version,user}) {
    const classes = useStyles();

    return (
        <footer>
            <AppBar position="static" elevation={0} className={classes.appBar} color={"primary"}>
                <Container maxWidth="lg">
                    <Toolbar className={classes.toolbar}>
                            <Typography variant="body2" color="inherit">
                                <Link href={"https://regionsyddanmark.dk/om-region-syddanmark/sadan-behandler-vi-dine-data-og-personoplysninger/persondatapolitik-for-region-syddanmark"} color="inherit" target={"_blank"}>RSDs Persondatapolitik</Link> &nbsp;&bull;&nbsp; <Link href={"https://www.was.digst.dk/regionsyddanmark-dk"} color="inherit" target={"_blank"}>Tilgængelighedserklæring</Link>
                                {user !== null && " • Ved problemer, kontakt 7663 1600"}
                            </Typography>
                            <div className={classes.grow} />
                            <Typography variant="body2" color="inherit" title={"Version: " + version}>
                                &copy; De Fem Regioner
                            </Typography>
                    </Toolbar>
                </Container>
            </AppBar>
        </footer>
    );
}
